import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionComparerService {

  constructor() {
  }

  public isVersionCompatible(requiredVersion: string, currentVersion: string): boolean {
    if (currentVersion === 'Preview Version') {
      // Assume that any version is compatible with the preview version.
      return true;
    }

    const requiredVersionParts = requiredVersion.split('.');
    const currentVersionParts = currentVersion.split('.');

    for (let i = 0; i < Math.min(requiredVersionParts.length, currentVersionParts.length); i++) {
      const requiredPart = parseInt(requiredVersionParts[i]);
      const currentPart = parseInt(currentVersionParts[i]);

      if (requiredPart > currentPart) {
        return false;
      } else if (requiredPart < currentPart) {
        // Stop comparing further if the required version is less than the current version.
        return true;
      }
      // If all parts are equal, continue comparing the next parts.
    }

    return requiredVersionParts.length <= currentVersionParts.length;
  }
}
