import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonApp, IonicRouteStrategy, IonRouterOutlet, provideIonicAngular } from '@ionic/angular/standalone';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ApiModule } from './api/client';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApiModule as RegistryApiModule, Configuration as RegistryConfiguration } from './api/client.registry';
import { CookieService } from 'ngx-cookie-service';
import { XsrfInterceptor } from './services/security/xsrf-interceptor.service';
import { AuthInterceptor } from './services/security/auth-interceptor.service';
import * as Sentry from "@sentry/angular";
import { ApiConfiguration } from "./api/api-configuration";
import { AppCompatibilityInterceptor } from "./services/security/app-compatibility-interceptor.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ApiModule.forRoot(
      () => ApiConfiguration.instance,
    ),
    RegistryApiModule.forRoot(
      () => new RegistryConfiguration({basePath: 'https://registry.templado.app'}),
    ),
    IonApp,
    IonRouterOutlet
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AppCompatibilityInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    provideHttpClient(withInterceptorsFromDi()),
    CookieService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideIonicAngular({mode: 'ios'})
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
