import { Component, Input } from '@angular/core';
import { IonButton, IonContent, IonIcon, Platform } from "@ionic/angular/standalone";
import { addIcons } from "ionicons";
import { logoAppleAppstore, logoGooglePlaystore, sadOutline } from "ionicons/icons";

@Component({
  selector: 'version-mismatch',
  templateUrl: './version-mismatch.component.html',
  styleUrls: ['./version-mismatch.component.scss'],
  imports: [
    IonButton,
    IonIcon,
    IonContent
  ],
  standalone: true
})
export class VersionMismatchComponent {

  @Input() minAppVersion: string;

  constructor(private platform: Platform) {
    addIcons({sadOutline, logoAppleAppstore, logoGooglePlaystore});
  }

  get currentVersion(): string {
    return globalThis.appVersion;
  }

  isApplePlatform() {
    return this.platform.is('ios');
  }
}
