import { Configuration } from "./client";

export class ApiConfiguration extends Configuration {
  public static instance: ApiConfiguration = new ApiConfiguration();

  constructor() {
    super();

    this.basePath = localStorage.getItem("apiBasePath");
    if (!this.basePath) {
      console.log("No API base path found in local storage. Using default base path.");
      this.basePath = this.getBaseUrlFromPageUrl();
    }
  }

  public setBasePath(basePath: string): void {
    this.basePath = basePath;
    localStorage.setItem("apiBasePath", basePath);
  }

  private getBaseUrlFromPageUrl() {
    return window.location.protocol + "//" + window.location.hostname
      + (window.location.port ? ":" + window.location.port : "");
  }
}
