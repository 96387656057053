import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { VersionComparerService } from "../util/version-comparer.service";
import { ModalController } from "@ionic/angular/standalone";
import { VersionMismatchComponent } from "../../version-mismatch/version-mismatch.component";

@Injectable()
export class AppCompatibilityInterceptor implements HttpInterceptor {

  private isModalVisible = false;

  constructor(private versionComparerService: VersionComparerService,
              private modalController: ModalController) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (globalThis.isPwa) {
            // If the PWA is not installed, just skip the version check.
            return;
          }
          const response = event as HttpResponse<any>;
          if (response?.headers?.has('X-Min-App-Version')) {
            const minAppVersion = response.headers.get('X-Min-App-Version');
            this.runVersionCheck(minAppVersion);
          }
        })
      )
  }

  private async runVersionCheck(minAppVersion: string) {
    if (this.isModalVisible) {
      // If a modal is already visible, don't open another one.
      return;
    }

    if (!this.versionComparerService.isVersionCompatible(minAppVersion, globalThis.appVersion)) {
      const modal = await this.modalController.create({
        component: VersionMismatchComponent,
        componentProps: {
          minAppVersion
        },
        canDismiss: false
      });

      this.isModalVisible = true;
      await modal.present();
    }
  }
}
