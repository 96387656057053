<ion-content [fullscreen]="true" class="ion-text-center ion-padding">
  <div class="center">

    <ion-icon name="sad-outline" class="large-icon"></ion-icon>
    <h1>Bitte aktualisieren</h1>
    <p>
      Die installierte Version der App wird nicht mehr unterstützt.
    </p>
    @if (minAppVersion) {
      <p>
        Aktuell ist Version <strong>{{ currentVersion }}</strong> installiert, benötigt wird jedoch Version
        <strong>{{ minAppVersion }}</strong>.
      </p>
    }
    <p>
      Bitte die aktuellste Version der App aus dem App-Store installieren, damit alles wie erwartet funktioniert.
    </p>
    @if (isApplePlatform()) {
      <ion-button fill="outline" href="https://apps.apple.com/us/app/templado/id6737799626">
        <ion-icon name="logo-apple-appstore" slot="start"></ion-icon>
        Zum App-Store
      </ion-button>
    } @else {
      <ion-button fill="outline" href="https://play.google.com">
        <ion-icon name="logo-google-playstore" slot="start"></ion-icon>
        Zum App-Store
      </ion-button>
    }
  </div>
</ion-content>
